<template>
  <div class="display-profile">
    <!-- Avatar | Name | Email  -->
    <div class="col-12 my-3 d-flex justify-content-center">
      <div>
        <img
          v-if="designer.avatar.length > 0"
          :src="$getFileURL(designer.avatar[0])"
          alt="Avatar"
          class="avatar-img"
        />
        <div v-else class="avatar-placeholder">
          <i class="fas fa-user-circle"></i>
        </div>
      </div>
      <div class="ml-3">
        <p class="name">{{ designer.name }}</p>
        <p class="email">{{ designer.email }}</p>
      </div>
    </div>

    <div class="row my-2">
      <button class="col-12 btn main bordered" @click="$emit('edit')">
        <i class="fas fa-pen mr-1"></i>Edit
      </button>
    </div>

    <!-- Personal DATA -->
    <div class="row mb-4">
      <h5 class="col-12 mb-2">Personal Data</h5>
      <display-data
        :class="dataCSS"
        label="Contact Number"
        :content="$getUserPhoneNumber(designer.user)"
      ></display-data>
      <display-data
        :class="dataCSS"
        label="Introduction"
        :content="designer.introduction"
      ></display-data>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    DisplayData: () => import("@/components/GlobalComponents/DisplayData")
  },
  mixins: [],
  props: {
    designer: {
      type: Object
    }
  },
  data: function () {
    return { dataCSS: "col-12 sm-col-6 md-col-4 lg-col-3 px-1 mb-2" };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
.display-profile {
  .status {
    width: fit-content;
    padding: 8px 24px;
    border-radius: 50em;
    color: white;
    background: $color-success;
    &.active {
      background: $color-success;
    }
    &.inactive {
      background: $color-warn;
    }
  }

  .avatar-placeholder {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #ddd;
    text-align: center;
    i {
      font-size: 80px;
      line-height: 1.25;
      color: white;
    }
  }
  .avatar-img {
    @include image(cover);
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }

  .name {
    font-size: 20px;
    font-weight: bold;
    color: #444;
  }

  .email {
    color: #777;
  }
}
</style>
